/** DentalInterventionForm.styles.jsx */

import styled from '@emotion/styled';

export const Form = styled.form`
    font-family: var(--health-card-font);

    height: 94%;
    background-color: white;
    border-radius: 16px;
    padding: 16px 16px 32px 16px;

    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 64px;

    overflow-y: auto; /* Enable vertical scrolling */
    -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
    scrollbar-width: none; /* Hide scrollbar for Firefox */

    /* Hide scrollbar for WebKit browsers (Chrome, Safari, etc.) */
    &::-webkit-scrollbar {
        display: none;
    }

    /* Prevent scroll chaining to parent */
    overscroll-behavior: contain;

    h2, h3, h4 {
        font-family: var(--health-card-font);
    }

    @media (max-width: 768px) {
        overscroll-behavior: auto;
        border-radius: 50px;
    }
`;

export const FormHeader = styled.div`
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;

    text-align: center;

    h2 {
        font-size: 24px;
    }
    p {
        font-size: 16px;
        color: #666;
    }
`;

export const ToothSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-bottom: 16px;

    p {
        color: var(--html-color);
        font-size: 16px;
    }
`;

export const ToothContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    font-weight: bold;
`;

export const FormSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;

    label {
        font-weight: bold;
    }

    h3 {
        font-size: 24px;
        font-weight: bold;
    }
`;

export const FormSubsection = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 8px;
    gap: 16px;

    label {
        display: block;
    }

    select,
    input[type='text'],
    input[type='number'],
    textarea {
        width: 100%;
        box-sizing: border-box;
    }
`;

export const FormSubSubsection = styled.div`
    display: flex;
    gap: 16px;
    align-items: center;
`;

export const SubmitButton = styled.button`
    flex-shrink: 0;
    width: 100%;
    height: 48px;
    padding: 10px 20px;
    background-color: #0085f2;
    box-shadow:
        0 4px 6px rgba(0, 133, 242, 0.2),
        0 1px 3px rgba(0, 133, 242, 0.1);
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;

    &:hover {
        background-color: #006bb3;
    }
`;

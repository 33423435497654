import * as React from 'react';
import { ReactNode } from 'react';

interface IPhonePreviewProps extends React.SVGProps<SVGSVGElement> {
    color?: string;
    style?: React.CSSProperties;
    children?: ReactNode;
}

export const IPhonePreview: React.FC<IPhonePreviewProps> = ({
    style,
    children,
    ...props
}) => (
    <svg
        width="360"
        height="730"
        viewBox="0 0 360 730"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={style}
        {...props}
    >
        <g clipPath="url(#clip0_456_3793)">
            <path
                d="M0.835768 140.934H6.15713C6.62015 140.934 6.9929 141.306 6.9929 141.769V168.069C6.9929 168.532 6.62015 168.905 6.15713 168.905H0.835768C0.372752 168.905 0 168.532 0 168.069V141.769C0 141.306 0.372752 140.934 0.835768 140.934Z"
                fill="#443C46"
            />
            <path
                d="M0.788455 141.695H6.20445C6.64125 141.695 6.9929 142.047 6.9929 142.485V167.354C6.9929 167.792 6.64125 168.144 6.20445 168.144H0.788455C0.351651 168.144 0 167.792 0 167.354V142.485C0 142.047 0.351651 141.695 0.788455 141.695Z"
                fill="url(#paint0_linear_456_3793)"
            />
            <path
                d="M0.835767 193.344H6.15713C6.62015 193.344 6.9929 193.716 6.9929 194.18V248.285C6.9929 248.748 6.62015 249.121 6.15713 249.121H0.835767C0.372752 249.121 0 248.748 0 248.285V194.18C0 193.717 0.372752 193.344 0.835767 193.344Z"
                fill="#443C46"
            />
            <path
                d="M0.788454 194.105H6.20445C6.64125 194.105 6.9929 194.457 6.9929 194.895V247.57C6.9929 248.008 6.64125 248.36 6.20445 248.36H0.788454C0.35165 248.36 0 248.008 0 247.57V194.895C0 194.457 0.35165 194.105 0.788454 194.105Z"
                fill="url(#paint1_linear_456_3793)"
            />
            <path
                d="M0.835767 263.925H6.15713C6.62015 263.925 6.9929 264.298 6.9929 264.761V318.866C6.9929 319.329 6.62015 319.702 6.15713 319.702H0.835767C0.372752 319.702 0 319.329 0 318.866V264.761C0 264.298 0.372752 263.925 0.835767 263.925Z"
                fill="#443C46"
            />
            <path
                d="M0.788454 264.686H6.20445C6.64125 264.686 6.9929 265.038 6.9929 265.476V318.15C6.9929 318.588 6.64125 318.941 6.20445 318.941H0.788454C0.35165 318.941 0 318.588 0 318.15V265.476C0 265.038 0.351651 264.686 0.788454 264.686Z"
                fill="url(#paint2_linear_456_3793)"
            />
            <path
                d="M359.165 231.536H353.844C353.381 231.536 353.008 231.908 353.008 232.371V318.015C353.008 318.478 353.381 318.851 353.844 318.851H359.165C359.628 318.851 360.001 318.478 360.001 318.015V232.371C360.001 231.908 359.628 231.536 359.165 231.536Z"
                fill="#443C46"
            />
            <path
                d="M359.212 232.297H353.796C353.359 232.297 353.008 232.649 353.008 233.087V317.299C353.008 317.737 353.359 318.09 353.796 318.09H359.212C359.649 318.09 360.001 317.737 360.001 317.299V233.087C360.001 232.649 359.649 232.297 359.212 232.297Z"
                fill="url(#paint3_linear_456_3793)"
            />
            <path
                d="M295.079 1H64.9208C31.1155 1 3.71094 28.4046 3.71094 62.2098V667.79C3.71094 701.595 31.1155 729 64.9208 729H295.079C328.885 729 356.289 701.595 356.289 667.79V62.2098C356.289 28.4046 328.885 1 295.079 1Z"
                fill="#E8E4EA"
                stroke="#443C46"
                strokeWidth="2"
            />
            <g filter="url(#filter0_f_456_3793)">
                <path
                    d="M293.659 2.98145H66.3422C32.9978 2.98145 5.9668 30.0124 5.9668 63.3568V666.643C5.9668 699.988 32.9978 727.019 66.3422 727.019H293.659C327.003 727.019 354.034 699.988 354.034 666.643V63.3568C354.034 30.0124 327.003 2.98145 293.659 2.98145Z"
                    fill="#49454B"
                />
            </g>
            <path
                d="M293.659 2.98145H66.3422C32.9978 2.98145 5.9668 30.0124 5.9668 63.3568V666.643C5.9668 699.988 32.9978 727.019 66.3422 727.019H293.659C327.003 727.019 354.034 699.988 354.034 666.643V63.3568C354.034 30.0124 327.003 2.98145 293.659 2.98145Z"
                fill="#7B6E81"
            />
            <g filter="url(#filter1_f_456_3793)">
                <path
                    d="M294.043 5.2832H65.9579C34.5139 5.2832 9.02344 30.7736 9.02344 62.2177V667.782C9.02344 699.226 34.5139 724.716 65.9579 724.716H294.043C325.487 724.716 350.977 699.226 350.977 667.782V62.2177C350.977 30.7736 325.487 5.2832 294.043 5.2832Z"
                    fill="black"
                />
            </g>
            <path
                d="M294.043 5.2832H65.9579C34.5139 5.2832 9.02344 30.7736 9.02344 62.2177V667.782C9.02344 699.226 34.5139 724.716 65.9579 724.716H294.043C325.487 724.716 350.977 699.226 350.977 667.782V62.2177C350.977 30.7736 325.487 5.2832 294.043 5.2832Z"
                fill="black"
            />

            <foreignObject
                x="19.3181"
                y="16.3857"
                width="322.19"
                height="697.23"
                style={{ borderRadius: 50 }}
            >
                {children}
            </foreignObject>
            {/* <path d="M297.683 16.3857H62.3181C38.3413 16.3857 18.9043 35.8228 18.9043 59.7996V670.2C18.9043 694.177 38.3413 713.614 62.3181 713.614H297.683C321.66 713.614 341.097 694.177 341.097 670.2V59.7996C341.097 35.8228 321.66 16.3857 297.683 16.3857Z" fill="#292929"/> */}

            <path
                d="M282.349 1H277.59V5.28338H282.349V1Z"
                fill="url(#paint4_linear_456_3793)"
            />
            <path
                d="M356.289 82.2309V77.4722H350.977V82.2309H356.289Z"
                fill="url(#paint5_linear_456_3793)"
            />
            <path
                d="M3.71094 82.2309V77.4722H9.0234V82.2309H3.71094Z"
                fill="url(#paint6_linear_456_3793)"
            />
            <path
                d="M77.6515 729H82.4102V724.717H77.6515V729Z"
                fill="url(#paint7_linear_456_3793)"
            />
            <path
                d="M3.71094 647.769L3.71094 652.527H9.0234V647.769H3.71094Z"
                fill="url(#paint8_linear_456_3793)"
            />
            <path
                d="M356.289 647.769V652.527H350.977V647.769H356.289Z"
                fill="url(#paint9_linear_456_3793)"
            />
            <path
                d="M141.203 5.2832C145.177 5.2832 145.074 6.10117 145.897 7.0293C146.497 7.70624 147.269 7.92188 148.791 7.92188H211.207C212.729 7.92188 213.5 7.70624 214.1 7.0293C214.922 6.10117 214.821 5.2832 218.795 5.2832H141.203Z"
                fill="url(#paint10_linear_456_3793)"
            />
            <path
                d="M216.091 25.3359H143.911C135.457 25.3359 128.604 32.1891 128.604 40.6429C128.604 49.0968 135.457 55.95 143.911 55.95H216.091C224.545 55.95 231.398 49.0968 231.398 40.6429C231.398 32.1891 224.545 25.3359 216.091 25.3359Z"
                fill="black"
            />
            <path
                d="M216.761 46.722C220.118 46.722 222.84 44.0003 222.84 40.643C222.84 37.2856 220.118 34.564 216.761 34.564C213.403 34.564 210.682 37.2856 210.682 40.643C210.682 44.0003 213.403 46.722 216.761 46.722Z"
                fill="#0A0A0A"
            />
            <path
                d="M216.761 44.3928C218.832 44.3928 220.511 42.714 220.511 40.6432C220.511 38.5723 218.832 36.8936 216.761 36.8936C214.69 36.8936 213.012 38.5723 213.012 40.6432C213.012 42.714 214.69 44.3928 216.761 44.3928Z"
                fill="#0A0D13"
            />
            <path
                d="M216.761 43.5C218.339 43.5 219.618 42.2209 219.618 40.643C219.618 39.0652 218.339 37.7861 216.761 37.7861C215.183 37.7861 213.904 39.0652 213.904 40.643C213.904 42.2209 215.183 43.5 216.761 43.5Z"
                fill="#091427"
            />
            <g filter="url(#filter2_f_456_3793)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M214.793 38.7314C214.455 38.7671 213.973 39.2842 213.938 40.3005C213.902 41.3169 214.348 41.8696 214.597 41.8696C214.847 41.8696 215.881 40.3897 214.793 38.7314Z"
                    fill="#235A91"
                    fillOpacity="0.556075"
                />
            </g>
            <g filter="url(#filter3_f_456_3793)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M217.226 38.3758C216.692 38.5402 216.589 39.4034 216.61 39.8967C216.63 40.39 217.329 41.6848 218.172 41.3971C219.015 41.1093 219.158 40.0406 218.809 39.3212C218.46 38.6018 217.822 38.088 217.226 38.3758Z"
                    fill="#235A91"
                    fillOpacity="0.556075"
                />
            </g>
        </g>
        <defs>
            <filter
                id="filter0_f_456_3793"
                x="4.62667"
                y="1.64131"
                width="350.747"
                height="726.717"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                />
                <feGaussianBlur
                    stdDeviation="0.670065"
                    result="effect1_foregroundBlur_456_3793"
                />
            </filter>
            <filter
                id="filter1_f_456_3793"
                x="7.6967"
                y="3.95647"
                width="344.607"
                height="722.087"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                />
                <feGaussianBlur
                    stdDeviation="0.663367"
                    result="effect1_foregroundBlur_456_3793"
                />
            </filter>
            <filter
                id="filter2_f_456_3793"
                x="213.72"
                y="38.5158"
                width="1.77701"
                height="3.5695"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                />
                <feGaussianBlur
                    stdDeviation="0.107828"
                    result="effect1_foregroundBlur_456_3793"
                />
            </filter>
            <filter
                id="filter3_f_456_3793"
                x="216.392"
                y="38.0788"
                width="2.81022"
                height="3.57535"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                />
                <feGaussianBlur
                    stdDeviation="0.107828"
                    result="effect1_foregroundBlur_456_3793"
                />
            </filter>
            <linearGradient
                id="paint0_linear_456_3793"
                x1="6.20445"
                y1="141.695"
                x2="6.20445"
                y2="168.144"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#D9D7DE" />
                <stop offset="0.08" stopColor="#43384A" />
                <stop offset="0.14" stopColor="#7B6E81" />
                <stop offset="0.86" stopColor="#7B6E81" />
                <stop offset="0.928265" stopColor="#5F5365" />
                <stop offset="1" stopColor="#B0A8B6" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_456_3793"
                x1="6.20445"
                y1="194.105"
                x2="6.20445"
                y2="248.36"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#D9D7DE" />
                <stop offset="0.08" stopColor="#43384A" />
                <stop offset="0.14" stopColor="#7B6E81" />
                <stop offset="0.86" stopColor="#7B6E81" />
                <stop offset="0.928265" stopColor="#5F5365" />
                <stop offset="1" stopColor="#B0A8B6" />
            </linearGradient>
            <linearGradient
                id="paint2_linear_456_3793"
                x1="6.20445"
                y1="264.686"
                x2="6.20445"
                y2="318.941"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#D9D7DE" />
                <stop offset="0.08" stopColor="#43384A" />
                <stop offset="0.14" stopColor="#7B6E81" />
                <stop offset="0.86" stopColor="#7B6E81" />
                <stop offset="0.928265" stopColor="#5F5365" />
                <stop offset="1" stopColor="#B0A8B6" />
            </linearGradient>
            <linearGradient
                id="paint3_linear_456_3793"
                x1="359.212"
                y1="232.297"
                x2="359.212"
                y2="318.09"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#D9D7DE" />
                <stop offset="0.08" stopColor="#43384A" />
                <stop offset="0.14" stopColor="#7B6E81" />
                <stop offset="0.86" stopColor="#7B6E81" />
                <stop offset="0.928265" stopColor="#5F5365" />
                <stop offset="1" stopColor="#B0A8B6" />
            </linearGradient>
            <linearGradient
                id="paint4_linear_456_3793"
                x1="277.59"
                y1="1"
                x2="277.59"
                y2="5.28338"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#8E7F93" />
                <stop offset="1" stopColor="#544B58" />
            </linearGradient>
            <linearGradient
                id="paint5_linear_456_3793"
                x1="356.289"
                y1="77.4722"
                x2="350.977"
                y2="77.4722"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#8E7F93" />
                <stop offset="1" stopColor="#544B58" />
            </linearGradient>
            <linearGradient
                id="paint6_linear_456_3793"
                x1="3.71091"
                y1="77.4722"
                x2="9.02337"
                y2="77.4722"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#8E7F93" />
                <stop offset="1" stopColor="#544B58" />
            </linearGradient>
            <linearGradient
                id="paint7_linear_456_3793"
                x1="82.4102"
                y1="729"
                x2="82.4102"
                y2="724.717"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#8E7F93" />
                <stop offset="1" stopColor="#544B58" />
            </linearGradient>
            <linearGradient
                id="paint8_linear_456_3793"
                x1="3.71092"
                y1="652.527"
                x2="9.02338"
                y2="652.527"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#8E7F93" />
                <stop offset="1" stopColor="#544B58" />
            </linearGradient>
            <linearGradient
                id="paint9_linear_456_3793"
                x1="356.289"
                y1="652.527"
                x2="350.977"
                y2="652.527"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#8E7F93" />
                <stop offset="1" stopColor="#544B58" />
            </linearGradient>
            <linearGradient
                id="paint10_linear_456_3793"
                x1="148.847"
                y1="5.2832"
                x2="148.847"
                y2="7.92188"
                gradientUnits="userSpaceOnUse"
            >
                <stop />
                <stop offset="1" stopColor="#212121" />
            </linearGradient>
            <clipPath id="clip0_456_3793">
                <rect width="360" height="730" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

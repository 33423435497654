export const PaletteLogo = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M25.1002 16.5312C24.7514 16.6756 24.3775 16.75 24 16.75C23.2375 16.75 22.5062 16.4471 21.9671 15.9079C21.4279 15.3688 21.125 14.6375 21.125 13.875C21.125 13.1125 21.4279 12.3812 21.9671 11.8421C22.5062 11.3029 23.2375 11 24 11C24.3775 11 24.7514 11.0744 25.1002 11.2188C25.449 11.3633 25.766 11.5751 26.0329 11.8421C26.2999 12.109 26.5117 12.426 26.6562 12.7748C26.8006 13.1236 26.875 13.4974 26.875 13.875C26.875 14.2525 26.8006 14.6264 26.6562 14.9752C26.5117 15.324 26.2999 15.641 26.0329 15.9079C25.766 16.1749 25.449 16.3867 25.1002 16.5312ZM35.0329 22.6579C34.4938 23.1971 33.7625 23.5 33 23.5C32.2375 23.5 31.5062 23.1971 30.9671 22.6579C30.4279 22.1188 30.125 21.3875 30.125 20.625C30.125 19.8625 30.4279 19.1312 30.9671 18.5921C31.5062 18.0529 32.2375 17.75 33 17.75C33.7625 17.75 34.4938 18.0529 35.0329 18.5921C35.5721 19.1312 35.875 19.8625 35.875 20.625C35.875 21.3875 35.5721 22.1188 35.0329 22.6579ZM17.875 21.75C17.875 22.5125 17.5721 23.2438 17.0329 23.7829C16.4938 24.3221 15.7625 24.625 15 24.625C14.2375 24.625 13.5062 24.3221 12.9671 23.7829C12.4279 23.2438 12.125 22.5125 12.125 21.75C12.125 20.9875 12.4279 20.2562 12.9671 19.7171C13.5062 19.1779 14.2375 18.875 15 18.875C15.7625 18.875 16.4938 19.1779 17.0329 19.7171C17.5721 20.2562 17.875 20.9875 17.875 21.75ZM21.5329 33.9079C20.9938 34.4471 20.2625 34.75 19.5 34.75C18.7375 34.75 18.0062 34.4471 17.4671 33.9079C16.9279 33.3688 16.625 32.6375 16.625 31.875C16.625 31.1125 16.9279 30.3812 17.4671 29.8421C18.0062 29.3029 18.7375 29 19.5 29C20.2625 29 20.9938 29.3029 21.5329 29.8421C22.0721 30.3812 22.375 31.1125 22.375 31.875C22.375 32.6375 22.0721 33.3688 21.5329 33.9079Z"
            fill="#1480EE"
            stroke="#1480EE"
        />
        <path
            d="M42 24C42 31.0875 37.8015 29.8163 33.9743 28.6575C31.695 27.9668 29.5463 27.3143 28.5 28.5C27.1433 30.0368 27.4313 32.5928 27.7103 35.07C28.1085 38.6138 28.491 42 24 42C20.4399 42 16.9598 40.9443 13.9997 38.9665C11.0397 36.9886 8.73255 34.1774 7.37018 30.8883C6.0078 27.5992 5.65134 23.98 6.34587 20.4884C7.04041 16.9967 8.75474 13.7894 11.2721 11.2721C13.7894 8.75474 16.9967 7.04041 20.4884 6.34587C23.98 5.65134 27.5992 6.0078 30.8883 7.37018C34.1774 8.73255 36.9886 11.0397 38.9665 13.9997C40.9443 16.9598 42 20.4399 42 24ZM24 39.75C25.3748 39.75 25.4715 39.3653 25.4738 39.354C25.6493 39.0255 25.7528 38.31 25.6313 36.8363C25.5998 36.4583 25.548 36.0038 25.494 35.5065C25.377 34.4625 25.242 33.2453 25.2285 32.217C25.206 30.6263 25.4153 28.5945 26.8125 27.0105C27.6428 26.0723 28.7138 25.71 29.6745 25.62C30.5835 25.5345 31.5015 25.6785 32.2845 25.854C33.0563 26.0273 33.864 26.2725 34.5908 26.493L34.6538 26.511C35.4323 26.7473 36.1343 26.9588 36.798 27.1095C38.2673 27.4425 38.832 27.2963 39.0278 27.1635C39.1133 27.105 39.75 26.6123 39.75 24C39.75 20.8849 38.8263 17.8398 37.0956 15.2498C35.365 12.6597 32.9052 10.641 30.0273 9.4489C27.1493 8.25682 23.9825 7.94492 20.9273 8.55264C17.8721 9.16036 15.0658 10.6604 12.8631 12.8631C10.6604 15.0658 9.16036 17.8721 8.55264 20.9273C7.94492 23.9825 8.25682 27.1493 9.4489 30.0273C10.641 32.9052 12.6597 35.365 15.2498 37.0956C17.8398 38.8263 20.8849 39.75 24 39.75Z"
            fill="#1480EE"
        />
    </svg>
);

import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { COLORS } from '../Constants';

// Define local color constants
export const HEX_COLORS = {
    white: '#FFFFFF',
    black: '#000000',
    primary: '#0085F2',
    secondary: '#7F8EA4',
    blackberry: '#142542',
    slate: 'rgba(138, 93, 242)',
    // background: 'rgba(127, 142, 164, 0.12)',
    background: '#F5F5F5',
    chatbotBackground: '#F1EBFB',
};

// Define local shadows
export const shadows = {
    light: 'rgba(20, 37, 66, 0.08) 0px 2px 16px',
};

const float = keyframes`
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
`;

// CHATBOT FRAME
export const ChatbotFrame = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 300px;
    height: 540px;
    background: ${HEX_COLORS.white};
    border-radius: 16px;
    box-shadow: ${shadows.light};
    box-sizing: border-box;
    overflow: hidden; // Hide overflow from padding

    animation: ${float} 3s ease-in-out infinite;

    font-family: var(--chatbot-font);
`;

// HEADER
export const ChatbotFrameHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 24px 16px;
    box-sizing: border-box; // Include padding within width
`;

export const JimHeader = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
`;

export const JimText = styled.p`
    font-weight: bold;
    font-size: 1rem;
    color: ${HEX_COLORS.slate};
`;

export const ResetButton = styled.button`
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    font: inherit;
    cursor: pointer;
    color: ${COLORS.border};
    font-size: 0.95rem;

    &:hover {
        color: ${COLORS.darkGray};
    }
`;

// CONTENT
export const ChatbotFrameContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 16px 16px;
    flex-grow: 1;
    gap: 8px;
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: border-box;

`;

// MESSAGE BUBBLE
type MessageBubbleProps = {
    isConsumer?: boolean;
};

export const MessageBubbleWrapper = styled.div<MessageBubbleProps>`
    display: flex;
    justify-content: ${({ isConsumer }) =>
        isConsumer ? 'flex-end' : 'flex-start'};
    width: 100%;
    gap: 4px;
`;

export const MessageBubble = styled.div<MessageBubbleProps>`
    display: flex;
    width: fit-content;
    padding: 11px 16px;
    border-radius: 20px;
    background: ${({ isConsumer }) =>
        isConsumer ? HEX_COLORS.primary : HEX_COLORS.background};
    color: ${({ isConsumer }) =>
        isConsumer ? HEX_COLORS.white : HEX_COLORS.black};
    max-width: 65%;
    overflow-wrap: break-word;
    word-break: break-word;
    white-space: pre-wrap;

    font-size: 14px;

    a {
        text-decoration: underline;
        color: ${({ isConsumer }) =>
        isConsumer ? HEX_COLORS.white : HEX_COLORS.black};
    }

    animation: 0.3s ease bubbleTransition;
    transition: transform 0.2s ease-in-out;

    @keyframes bubbleTransition {
        0% {
            transform: translateY(100%) scale(0);
        }
        100% {
            transform: translateY(0) scale(1);
        }
    }
`;

export const JimUser = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: ${COLORS.chatbotBackground};

    svg {
        width: 16px;
        height: 16px;
    }
`;

// FOOTER
export const ChatbotFrameFooter = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 16px;
    border-top: 1px solid rgba(20, 37, 66, 0.12);
    gap: 8px;
    box-sizing: border-box;

    textarea {
        border-radius: 24px;
        background-color: #f6f8fc;
        padding: 8px 16px;
        border: none;
        flex-grow: 1;
        max-height: 80px;
        resize: none;

        ::placeholder {
            color: ${HEX_COLORS.secondary};
        }

        :focus {
            outline: none;
        }
    }
`;

export const ChatbotTextArea = styled.textarea`
    height: 1.25rem;
    font-family: var(--chatbot-font);
    font-size: 0.9rem;
`;

// SEND MESSAGE BUTTON
export const SendMessageButton = styled.button`
    flex-shrink: 0;
    margin: auto auto 0;
    height: 36px;
    width: 36px;
    background: none;
    border: none;
    cursor: pointer;

    svg {
        height: 100%;
        width: 100%;
        fill: ${HEX_COLORS.primary};
        margin: 0;
        padding: 0;
        display: block;
    }
`;

// TYPING INDICATOR
export const TypingIndicator = styled.div`
    min-width: 69px;
    min-height: 40px;
    // background: ${HEX_COLORS.chatbotBackground};
    background-repeat: no-repeat;
    background-size: contain;
    transform-origin: 0% 100%;



    animation:
        imageCycle 0.7s infinite,
        scaleBubble 0.3s ease;

    @keyframes imageCycle {
        0%,
        33% {
            background-image: url('/assets/bulle_step_1.svg');
        }
        34%,
        66% {
            background-image: url('/assets/bulle_step_2.svg');
        }
        67%,
        100% {
            background-image: url('/assets/bulle_step_3.svg');
        }
    }

    @keyframes scaleBubble {
        0% {
            transform: scale(0);
        }
        100% {
            transform: scale(1);
        }
    }
`;

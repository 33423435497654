import React from 'react';
import { Controller, Control } from 'react-hook-form';
import Switch from './Switch'; // Adjust the import path as needed

/** Type Definitions */
type SwitchFieldProps = {
    name: string;
    control: Control<any>;
    defaultValue?: boolean;
    isDisabled?: boolean;
};

/** SwitchField Component */
const SwitchField: React.FC<SwitchFieldProps> = ({
    name,
    control,
    defaultValue = false,
    isDisabled,
}) => {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({ field: { value, onChange } }) => (
                <Switch
                    checked={value}
                    onChange={onChange}
                    isDisabled={isDisabled}
                />
            )}
        />
    );
};

export default SwitchField;

import styled from '@emotion/styled';

export const HomeContainer = styled.header`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 2rem;
    height: 90vh;
`;

export const Title = styled.h1`
    font-weight: normal;
    line-height: 1;
    margin: 0;
`;

export const Presentation = styled.p`
    font-size: 1.25rem;
`;

export const JobItems = styled.ul`
    display: flex;
    gap: 1rem;
    flex-direction: row;
    align-items: center;
    list-style: none;
    font-size: var(--font-size-h4);
    padding: 0;

    @media (max-width: 768px) {
        gap: 0.5rem;
        align-items: flex-start;
        flex-direction: column;
    }
`;

export const ResourceItems = styled.ul`
    display: flex;
    gap: 2rem;
    flex-direction: row;
    align-items: center;
    list-style: none;
    padding: 0;
`;

export const StatusUpdate = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;

    p {
        font-size: 1.25rem;
    }
`;

export const Blob = styled.div`
    background: rgba(78, 214, 59, 1);
    box-shadow: 0 0 0 0 rgba(78, 214, 59, 1);
    border-radius: 50%;
    margin: 10px;
    height: 20px;
    width: 20px;
    transform: scale(1);

    animation: pulse-green 2s infinite;

    @keyframes pulse-green {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(78, 214, 59, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(78, 214, 59, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(78, 214, 59, 0);
    }
}
`;
// servicesData.ts
import React from 'react';
import { BiCodeAltLogo } from '../assets/BiCodeAltLogo';
import { SparklesLogo } from '../assets/SparklesLogo';
import { LightbulbLogo } from '../assets/LightbulbLogo';
import { RocketTakeoffLogo } from '../assets/RocketTakeoffLogo';
import { PaletteLogo } from '../assets/PaletteLogo';

export interface Service {
    title: string;
    description: string;
    icon: React.ReactNode;
}

export const servicesData: Service[] = [
    {
        title: 'Full Stack Development',
        description: 'Web & mobile apps with Python, React, and React Native.',
        icon: <BiCodeAltLogo />,
    },
    {
        title: 'AI-Powered Chatbots',
        description:
            'Custom multi-agent chatbots for popular messaging platforms.',
        icon: <SparklesLogo />,
    },
    {
        title: 'Product Management',
        description:
            'From concept to MVP with a focus on user experience and scalability.',
        icon: <LightbulbLogo />,
    },
    {
        title: 'Startup Consulting',
        description:
            'Insights on product development and early-stage strategy.',
        icon: <RocketTakeoffLogo />,
    },
    {
        title: 'UX/UI Design',
        description: 'Prototyping and user-centered design with Figma.',
        icon: <PaletteLogo />,
    },
];

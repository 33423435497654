import React from 'react';
import { Controller, Control } from 'react-hook-form';
import styled from '@emotion/styled';
import { COLORS } from '../Constants';

/** Type definitions */
type SelectableItem = {
    display: string; // Value to display on UI
    value: string | number; // Actual value
};

interface SelectableButtonsProps {
    selectedValue: string | number | undefined;
    setSelectedValue: React.Dispatch<React.SetStateAction<string | number>>;
    items: Array<SelectableItem>;
    isDisabled?: boolean;
    textColor?: string;
    textWeightOnSelected?: 'bold' | 'normal';
    textWeightOnUnselected?: 'bold' | 'normal';
    buttonStyle?: React.CSSProperties;
    containerStyle?: React.CSSProperties;
    label?: string;
    /** Any other props you may need */
}

interface SelectableButtonsFieldProps
    extends Omit<SelectableButtonsProps, 'selectedValue' | 'setSelectedValue'> {
    name: string;
    control: Control<any>;
    required?: boolean;
    errorMessage: string;
    centerItems?: boolean;
}

const ErrorContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
`;

const ErrorMessage = styled.span`
    color: ${COLORS.danger};
    font-size: 0.75rem;
    margin-top: 4px;
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;
    width: 100%;
`;

const Button = styled.button<{ isSelected: boolean }>`
    padding: 8px 12px;
    border: 1px solid #d3d3d3;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: ${(props) => (props.isSelected ? '#e6f7ff' : '#fff')};
    font-weight: ${(props) => (props.isSelected ? 'bold' : 'normal')};
    color: ${(props) => (props.isSelected ? '#1890ff' : '#000')};
    cursor: pointer;
    outline: none;
    flex: 1 0 calc(50% - 8px);
    text-align: center;
    min-width: 80px;
    max-width: 100%;

    &:hover {
        background-color: #f5f5f5;
    }

    &:disabled {
        color: #ccc;
        cursor: not-allowed;
    }
`;

/** SelectableButtons component */
const SelectableButtons: React.FC<SelectableButtonsProps> = ({
    selectedValue,
    setSelectedValue,
    items,
    isDisabled,
    textColor,
    textWeightOnSelected = 'bold',
    textWeightOnUnselected = 'normal',
    buttonStyle,
    containerStyle,
}) => {
    return (
        <ButtonsContainer style={containerStyle}>
            {items.map((item, index) => (
                <Button
                    key={index}
                    type="button"
                    isSelected={selectedValue === item.value}
                    onClick={() => setSelectedValue(item.value)}
                    disabled={isDisabled}
                    style={{
                        ...buttonStyle,
                        fontWeight:
                            selectedValue === item.value
                                ? textWeightOnSelected
                                : textWeightOnUnselected,
                        color:
                            textColor ||
                            (selectedValue === item.value ? '#1890ff' : '#000'),
                    }}
                >
                    {item.display}
                </Button>
            ))}
        </ButtonsContainer>
    );
};

/** SelectableButtonsField component integrating with react-hook-form */
const SelectableButtonsField: React.FC<SelectableButtonsFieldProps> = ({
    name,
    control,
    items,
    errorMessage,
    isDisabled,
    ...props
}) => {
    return (
        <Controller
            control={control}
            name={name}
            rules={{ required: errorMessage }}
            render={({ field: { onChange, value }, fieldState }) => (
                <ErrorContainer>
                    <SelectableButtons
                        selectedValue={value}
                        setSelectedValue={onChange}
                        items={items}
                        isDisabled={isDisabled}
                        {...props}
                    />
                    {fieldState.error && (
                        <ErrorMessage>{fieldState.error.message}</ErrorMessage>
                    )}
                </ErrorContainer>
            )}
        />
    );
};

export default SelectableButtonsField;

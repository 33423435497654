import { MdFileDownload } from 'react-icons/md';
import {
    AboutContainer,
    CTAButton,
} from './About.styled';

type Props = {
    id: string;
};

export const About = ({ id }: Props) => {
    return (
        <AboutContainer id={id}>
            <div>
                <h2>Let’s work together</h2>
                <h4>
                    Reach out for collaborations, freelance inquiries, or just to connect.
                </h4>
            </div>
            <p>
                Feel free to <a href="https://www.linkedin.com/in/eg-955a62198/" target='_blank' style={{ textDecoration: 'underline', color: 'var(--primary-color)' }}>connect with me on LinkedIn</a>.<br />
                For freelance inquiries, send me a message at <span style={{ textDecoration: 'underline', color: 'var(--primary-color)' }}>eliyahou.pro@gmail.com</span>
            </p>

            <CTAButton href="#projects">
                Download my resume <MdFileDownload />
            </CTAButton>

        </AboutContainer>
    );
};

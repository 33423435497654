import React from 'react';
import {
    ServicesContainer,
    ServicesItem,
    ServicesItems,
    ServieItemTick,
} from './Services.styled';

import { servicesData } from './ServicesData';

export const ServicesList = ({ id }: { id: string }) => {
    return (
        <ServicesContainer id={id}>
            <div>
                <h2>Services</h2>
                <h4>How I’ll make your project shine.</h4>
            </div>

            <ServicesItems>
                {servicesData.map((service, index) => {
                    return (
                        <ServicesItem key={service.title} popular={index == 0}>
                            {index == 0 && <ServieItemTick>Popular</ServieItemTick>}
                            {service.icon}
                            <h5>{service.title}</h5>
                            <p>{service.description}</p>
                            {/* <ServiceButton aria-labl={`Learn more about ${service.title}`}>Learn More</ServiceButton> */}
                        </ServicesItem>
                    );
                })}
            </ServicesItems>
        </ServicesContainer>
    );
};

export default ServicesList;

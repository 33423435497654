export const SendMessageIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
            fill="#0085F2"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M34.0708 15.0708L23.4649 18.607L12.8579 22.1421L20.2603 27.2773L28.9977 20.1438L21.9927 29.0097L27 36.2843L30.5351 25.6772L34.0708 15.0708Z"
            fill="white"
        />
    </svg>
);

export const AzureLogo = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M9.00069 4.01986H14.327L8.79777 20.4025C8.74094 20.5709 8.63275 20.7172 8.48842 20.8208C8.34409 20.9244 8.1709 20.9802 7.99323 20.9802H3.848C3.71338 20.9802 3.58069 20.9482 3.46091 20.8867C3.34113 20.8253 3.2377 20.7362 3.15918 20.6269C3.08066 20.5175 3.0293 20.3911 3.00936 20.2579C2.98941 20.1248 3.00146 19.9888 3.04449 19.8613L8.19595 4.59754C8.25275 4.42912 8.36096 4.28277 8.50533 4.1791C8.6497 4.07543 8.82295 4.01987 9.00069 4.01986Z"
            fill="url(#paint0_linear_483_8025)"
        />
        <path
            d="M16.74 15.0083H8.29369C8.21517 15.0082 8.13844 15.0318 8.07349 15.0759C8.00855 15.1201 7.95841 15.1827 7.9296 15.2558C7.90079 15.3288 7.89465 15.4089 7.91198 15.4855C7.9293 15.5621 7.96929 15.6317 8.02674 15.6852L13.4542 20.7509C13.6122 20.8983 13.8202 20.9803 14.0363 20.9803H18.819L16.74 15.0083Z"
            fill="#0078D4"
        />
        <path
            d="M9.00156 4.0199C8.82188 4.01921 8.64669 4.07599 8.50158 4.18195C8.35647 4.2879 8.24904 4.43748 8.19498 4.60883L3.0517 19.8474C3.00577 19.9754 2.99135 20.1126 3.00966 20.2474C3.02797 20.3822 3.07847 20.5105 3.15689 20.6217C3.23531 20.7328 3.33934 20.8234 3.46018 20.8858C3.58102 20.9482 3.71512 20.9806 3.85112 20.9803H8.10333C8.2617 20.952 8.40973 20.8821 8.53228 20.7779C8.65483 20.6737 8.74752 20.5388 8.80089 20.387L9.82655 17.3642L13.4902 20.7814C13.6438 20.9084 13.8364 20.9786 14.0356 20.9803H18.8004L16.7106 15.0083L10.6186 15.0097L14.3471 4.0199H9.00156Z"
            fill="url(#paint1_linear_483_8025)"
        />
        <path
            d="M15.8041 4.59676C15.7474 4.42862 15.6394 4.28251 15.4952 4.17902C15.351 4.07553 15.1781 4.01988 15.0006 4.0199H9.06445C9.2419 4.01991 9.41487 4.07557 9.55902 4.17906C9.70316 4.28254 9.81122 4.42863 9.86797 4.59676L15.0196 19.8611C15.0627 19.9887 15.0748 20.1247 15.0548 20.2578C15.0349 20.391 14.9836 20.5175 14.905 20.6269C14.8265 20.7363 14.7231 20.8254 14.6033 20.8869C14.4835 20.9484 14.3508 20.9804 14.2161 20.9805H20.1525C20.2871 20.9804 20.4198 20.9483 20.5396 20.8868C20.6593 20.8253 20.7628 20.7362 20.8413 20.6268C20.9198 20.5175 20.9711 20.391 20.991 20.2578C21.0109 20.1246 20.9988 19.9887 20.9558 19.8611L15.8041 4.59676Z"
            fill="url(#paint2_linear_483_8025)"
        />
        <defs>
            <linearGradient
                id="paint0_linear_483_8025"
                x1="10.942"
                y1="5.27668"
                x2="5.41044"
                y2="21.6182"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#114A8B" />
                <stop offset="1" stop-color="#0669BC" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_483_8025"
                x1="12.6708"
                y1="12.8923"
                x2="11.3912"
                y2="13.325"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-opacity="0.3" />
                <stop offset="0.071" stop-opacity="0.2" />
                <stop offset="0.321" stop-opacity="0.1" />
                <stop offset="0.623" stop-opacity="0.05" />
                <stop offset="1" stop-opacity="0" />
            </linearGradient>
            <linearGradient
                id="paint2_linear_483_8025"
                x1="11.9666"
                y1="4.80009"
                x2="18.0385"
                y2="20.977"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#3CCBF4" />
                <stop offset="1" stop-color="#2892DF" />
            </linearGradient>
        </defs>
    </svg>
);

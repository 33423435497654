import React from 'react';
import {
    HomeContainer,
    JobItems,
    Presentation,
    ResourceItems,
    StatusUpdate,
    Title,
    Blob,
} from './Home.styled';
import GitHubIcon from '../assets/AiFillGithub';
import LinkedInIcon from '../assets/LinkedinLogo';
import useIsSmallScreen from '../isSmallScreen';

const Home = () => {
    const isSmallScreen = useIsSmallScreen();

    return (
        <HomeContainer>
            <Title>
                Hi, I'm{' '}
                <span
                    style={{
                        fontWeight: 'bold',
                        color: 'var(--primary-color)',
                    }}
                >
                    Eliyahou Gabbay
                </span>
            </Title>
            <JobItems>
                <li>Full Stack Engineer</li>
                {!isSmallScreen && <span>|</span>}
                <li>AI Solutions Architect</li>
                {!isSmallScreen && <span>|</span>}
                <li>Product thinker & design enthusiast</li>
            </JobItems>
            <Presentation>
                I’m a full stack engineer with a passion for merging AI and
                product design to create intelligent, user-centric applications.
                From developing advanced AI chatbots to defining impactful
                product strategies, I specialize in crafting solutions that not
                only enhance user experiences but also drive meaningful business
                outcomes.
            </Presentation>

            <ResourceItems>
                <StatusUpdate>
                    <Blob />
                    <p>Available</p>
                </StatusUpdate>
                <a
                    href="https://linkedin.com/in/eg-955a62198/"
                    target="_blank"
                    rel="noreferrer"
                >
                    <LinkedInIcon />
                </a>
                <a href="https://github.com/eliyahougabbay" target="_blank">
                    <GitHubIcon />
                </a>
            </ResourceItems>
        </HomeContainer>
    );
};

export default Home;

import { useState, useEffect } from 'react';

function useIsSmallScreen(breakpoint = 768) {
    // Initialize state with a boolean indicating if the screen is small
    const [isSmallScreen, setIsSmallScreen] = useState(
        typeof window !== 'undefined' ? window.innerWidth <= breakpoint : false
    );

    useEffect(() => {
        // Create a media query list
        const mediaQueryList = window.matchMedia(`(max-width: ${breakpoint}px)`);

        // Define a callback function to handle changes
        const handleScreenChange = (event: any) => {
            setIsSmallScreen(event.matches);
        };

        // Add the listener
        mediaQueryList.addEventListener('change', handleScreenChange);

        // Set the initial value
        setIsSmallScreen(mediaQueryList.matches);

        // Cleanup function to remove the listener on unmount
        return () => {
            mediaQueryList.removeEventListener('change', handleScreenChange);
        };
    }, [breakpoint]);

    return isSmallScreen;
}

export default useIsSmallScreen;

import styled from '@emotion/styled';

export const AutomatedIconWrapper = styled.div<{ size: number }>`
    border-radius: 100px;
    background-color: rgba(138, 93, 242, 0.12);'
    color: rgba(138, 93, 242);
    box-sizing: border-box;
    ${({ size }) => `width: ${size}px; height: ${size}px;`}
    display: flex;
    justify-content: center;
    align-items: center;
`;

import React from 'react';
import { Controller, Control } from 'react-hook-form';
import styled from '@emotion/styled';
import { DentalFormData } from './DentalFormContext';
import { COLORS } from '../Constants';

/** Styled Components */
const Container = styled.div<{ inline?: boolean }>`
    display: flex;
    flex-direction: ${(props) => (props.inline ? 'row' : 'column')};
    align-items: ${(props) => (props.inline ? 'center' : 'flex-start')};
    width: 100%;
    margin-top: 8px;
`;

const ErrorContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const ErrorMessage = styled.span`
    color: ${COLORS.danger};
    font-size: 0.75rem;
    margin-top: 4px;
`;

const StyledLabel = styled.label<{ inline?: boolean }>`
    margin-right: ${(props) => (props.inline ? '24px' : '0')};
    margin-bottom: ${(props) => (props.inline ? '0' : '8px')};
    font-weight: bold;
`;

const StyledInput = styled.input<{
    width?: string | number;
    height?: string | number;
    unit?: boolean;
}>`
    padding: 12px 0px;
    border: none;
    margin-right: 4px;
    width: ${(props) =>
        typeof props.width === 'number'
            ? `${props.width}px`
            : props.width || '100%'};
    height: ${(props) =>
        props.height
            ? typeof props.height === 'number'
                ? `${props.height}px`
                : props.height
            : 'auto'};

    appearance: none; /* Remove browser-specific styling */

    /* WebKit browsers (Chrome, Safari, Edge) */
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none; /* Remove arrows */
        appearance: none;
        margin: 0;
    }

    /* Firefox */
    &[type='number'] {
        -moz-appearance: textfield; /* Remove arrows */
    }

    &::placeholder {
        color: #aaa; /* Optional: Placeholder color */
    }

    &:focus {
        outline: none;
    }
`;

const UnitContainer = styled.div<{ unit?: string }>`
    padding: 0 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;

    border: 1px solid #ccc;
    border-radius: 4px;

    #unit {
        color: #666;
    }

    &:focus-within {
        box-shadow: 0 0 0 2px rgba(0, 123, 255);
    }
`;

const Suffix = styled.span``;

/** Type Definitions */
interface FormFieldProps {
    name: keyof DentalFormData;
    control: Control<any>;
    isRequired?: boolean;
}

interface TextInputFieldProps
    extends FormFieldProps,
        Omit<React.InputHTMLAttributes<HTMLInputElement>, 'name'> {
    label?: string; // Display a string on the line before the field
    suffix?: string; // Display a string after the field
    width?: string | number;
    height?: string | number;
    inline?: boolean; // If true, the label is next to the input; otherwise, it's above
    unit?: string;
}

/** TextInputField Component */
const TextInputField: React.FC<TextInputFieldProps> = ({
    control,
    name,
    label,
    suffix,
    width = '100%',
    height,
    inline,
    unit,
    isRequired,
    ...props
}) => {
    return (
        <Container inline={inline}>
            {label && (
                <StyledLabel htmlFor={name} inline={inline}>
                    {label}
                </StyledLabel>
            )}

            <Controller
                control={control}
                name={name}
                render={({ field, fieldState }) => (
                    <ErrorContainer>
                        <UnitContainer unit={unit}>
                            <StyledInput
                                {...field}
                                id={name}
                                width={width}
                                height={height}
                                required={isRequired}
                                {...props}
                            />
                            <span id="unit">{unit}</span>
                        </UnitContainer>
                        {fieldState.error && (
                            <ErrorMessage>
                                {fieldState.error.message}
                            </ErrorMessage>
                        )}
                    </ErrorContainer>
                )}
            />

            {suffix && <Suffix>{suffix}</Suffix>}
        </Container>
    );
};

export default TextInputField;

export const COLORS = {
    // custom colors
    jetBlack: '#121212',
    AppleGrey: '#f5f5f7',
    border: '#b3b3b3',
    ghostWhite: '#f8f8ff',
    darkGray: '#2C2C2C',
    chatbotBackground: '#F1EBFB',

    primaryPurple: '#6A0DAD',
    darkPurple: '#4B0082',

    // Health Card Solutions colors
    primary: '#0085f2',
    hover: '#006bb8',
    grey: '#f2f2f2',
    lightGrey: '#fafafa',

    // basic colors
    white: '#ffffff',
    black: '#000000',
    danger: '#FF0000',
    important: '#faad14',
};

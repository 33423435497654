import React, { useContext, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
    Form,
    FormHeader,
    FormSection,
    FormSubsection,
    FormSubSubsection,
    SubmitButton,
    ToothContainer,
    ToothSection,
} from './DentalInterventionForm.styled';
import SelectableButtonsField from './SelectableButtonsField';
import ImplantField from './ImplantField';
import TextInputField from './TextInputFiled';
import SwitchField from './SwitchField';
import SwitchBarField from './SwitchBarField';
import { CommentInput } from './ImplantField.styled';
import { ToothIcon } from './icons/Tooth';
import {
    DentalFormData,
    DentalInterventionFormContext,
} from './DentalFormContext';

const DentalInterventionForm = () => {
    const { formData, setFormData } = useContext(DentalInterventionFormContext);
    const { control, handleSubmit, watch, setValue } = useForm<DentalFormData>({
        defaultValues: formData,
    });

    // Watch the toggles
    const hasTransplant = watch('has_transplant', false);
    const hasMembrane = watch('has_membrane', false);
    const hasSuture = watch('has_suture', false);
    const hasLoadingType = watch('has_loading_type', false);
    const hasIncident = watch('has_incident', false);

    // Watch transplant_type to reset transplant_implant
    const transplantType = watch('transplant_type');

    useEffect(() => {
        if (transplantType === 'allo_transplant') {
            // Reset transplant_implant to default implant values
            setValue('transplant_implant', { udiDi: '', comment: '' });
        }
    }, [transplantType, setValue]);

    // Form options and values
    const transplantMethodValues = [
        { display: 'Sinus', value: 'sinus' },
        { display: 'Crête', value: 'crete' },
    ];
    const transplantTypeValues = [
        { display: 'Autogreffe', value: 'auto_transplant' },
        { display: 'Allogreffe', value: 'allo_transplant' },
        { display: 'Xénogreffe', value: 'xeno_transplant' },
    ];
    const boneTypeValues = [
        { display: 'I', value: 'I' },
        { display: 'II', value: 'II' },
        { display: 'III', value: 'III' },
        { display: 'IV', value: 'IV' },
    ];
    const threadSizeValues = [
        { display: '2.0', value: '2.0' },
        { display: '3.0', value: '3.0' },
        { display: '4.0', value: '4.0' },
        { display: '5.0', value: '5.0' },
    ];
    const loadTypeValues = [
        { display: 'Immédiate', value: 'immediate' },
        { display: 'Différée', value: 'deferred' },
    ];

    const onSubmit = (data: DentalFormData) => {
        setFormData(data);
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            {/* Form Header */}
            <FormHeader>
                <h2>Chirurgie implantaire</h2>
                <p>Veuillez remplir le detail de l'intervention</p>
            </FormHeader>

            {/* Tooth Section */}
            <ToothSection>
                <p>Dent selectionnée</p>
                <ToothContainer>
                    <ToothIcon />
                    <p>25</p>
                </ToothContainer>
            </ToothSection>

            {/* Greffe Section */}
            <FormSection>
                <SwitchBarField
                    control={control}
                    name="has_transplant"
                    title="Greffe"
                />
                {hasTransplant && (
                    <FormSubsection>
                        <SelectableButtonsField
                            name="transplant_method"
                            aria-label="transplant_method"
                            control={control}
                            items={transplantMethodValues}
                            required={hasTransplant}
                            errorMessage="Méthode de greffe est requise"
                            centerItems
                        />
                        <SelectableButtonsField
                            name="transplant_type"
                            aria-label="transplant_type"
                            control={control}
                            items={transplantTypeValues}
                            required={hasTransplant}
                            errorMessage="Type de greffe est requis"
                            centerItems
                        />
                    </FormSubsection>
                )}
            </FormSection>

            {/* Membrane Section */}
            <FormSection>
                <SwitchBarField
                    control={control}
                    name="has_membrane"
                    title="Membrane"
                />
                {hasMembrane && (
                    <FormSubsection>
                        <ImplantField
                            name="membrane_implant"
                            control={control}
                            setValue={setValue}
                        />
                    </FormSubsection>
                )}
            </FormSection>

            {/* Implant Section */}
            <FormSection>
                <h3>
                    Implant<span style={{ color: 'red' }}>*</span>
                </h3>
                <ImplantField
                    name="membrane_implant"
                    control={control}
                    setValue={setValue}
                />
                <label>Type de l'os</label>
                <SelectableButtonsField
                    name="bone_type"
                    control={control}
                    items={boneTypeValues}
                    required={true}
                    errorMessage="Type de l'os est requis"
                />

                <TextInputField
                    control={control}
                    name="torque"
                    label="Couple de serrage"
                    width={56}
                    type="number"
                    unit="N.cm"
                    isRequired
                    inline
                />
            </FormSection>

            {/* Suture Section */}
            <FormSection>
                <SwitchBarField
                    control={control}
                    name="has_suture"
                    title="Suture"
                />

                {hasSuture && (
                    <FormSubsection>
                        <FormSubSubsection>
                            <label>Résorbable</label>
                            <SwitchField
                                control={control}
                                name="suture_is_absorbable"
                            />
                        </FormSubSubsection>
                        <label>Taille</label>
                        <SelectableButtonsField
                            name="suture_size"
                            control={control}
                            items={threadSizeValues}
                            required={hasSuture}
                            errorMessage="Taille de suture est requise"
                        />
                    </FormSubsection>
                )}
            </FormSection>

            {/* Mise en charge Section */}
            <FormSection>
                <SwitchBarField
                    control={control}
                    name="has_loading_type"
                    title="Mise en charge"
                />

                {hasLoadingType && (
                    <FormSubsection>
                        <SelectableButtonsField
                            name="implant_loading_type"
                            control={control}
                            items={loadTypeValues}
                            required={hasLoadingType}
                            errorMessage="Type de mise en charge est requis"
                        />
                    </FormSubsection>
                )}
            </FormSection>

            {/* Incident Section */}
            <FormSection>
                <SwitchBarField
                    control={control}
                    name="has_incident"
                    title="Incident"
                />

                {hasIncident && (
                    <FormSubsection>
                        <Controller
                            name="incident"
                            control={control}
                            render={({ field }) => (
                                <CommentInput
                                    placeholder="Description de l'incident"
                                    rows={4}
                                    {...field}
                                />
                            )}
                        />
                    </FormSubsection>
                )}
            </FormSection>

            <SubmitButton type="submit">Envoyer</SubmitButton>
        </Form>
    );
};

export default DentalInterventionForm;

import React from 'react';
import {
    AdditionalInfo,
    InfoContainer,
    KeyProjectsSection,
    LinkToProjectBlog,
    PossibleLink,
    ProjectContainer,
    ProjectContent,
    ProjectPreviewContainer,
    ProjectsContainer,
    ProjectsHeader,
    StackContainer,
    StackItem,
} from './Project.styled';
import { DentalForm } from '../healthCardSolutions/DentalForm';
import { ReactLogo } from '../assets/ReactLogo';
import { HiOutlineArrowUpRight } from 'react-icons/hi2';
import { PythonLogo } from '../assets/PythonLogo';
import { OpenAILogo } from '../assets/OpenAILogo';
import { AzureLogo } from '../assets/AzureLogo';
import { ReactNativeLogo } from '../assets/ReactNativeLogo';
import { ChatbotPreview } from '../partoo/DhatbotPreview';

type Props = {
    title: React.ReactNode;
    description: string;
    blogLink: { label: string; url?: URL };
    component?: React.ReactNode;
    invert?: boolean;
    stack: { icon: JSX.Element }[];
    year: string;
};

const ChatbotStack = [
    {
        icon: (
            <ReactLogo>
                <title>React</title>
            </ReactLogo>
        ),
    },
    {
        icon: (
            <PythonLogo>
                <title>Python</title>
            </PythonLogo>
        ),
    },
    {
        icon: (
            <OpenAILogo>
                <title>React</title>
            </OpenAILogo>
        ),
    },
    {
        icon: (
            <AzureLogo>
                <title>React</title>
            </AzureLogo>
        ),
    },
];

const healthCardSolutionsStack = [
    {
        icon: (
            <ReactNativeLogo>
                <title>React</title>
            </ReactNativeLogo>
        ),
    },
    {
        icon: (
            <PythonLogo>
                <title>Python</title>
            </PythonLogo>
        ),
    },
];

const Stack = ({ stack }: { stack: { icon: JSX.Element }[] }) => {
    return (
        <StackContainer>
            {stack.map((item, index) => (
                <StackItem key={index}>{item.icon}</StackItem>
            ))}
        </StackContainer>
    );
};

export const Project = ({
    title,
    description,
    blogLink,
    component,
    invert,
    stack,
    year,
}: Props) => {
    return (
        <ProjectContainer invert={invert}>
            <ProjectContent invert={invert}>
                <h3>{title}</h3>
                <p>{description}</p>
                {blogLink.url ? (
                    <LinkToProjectBlog
                        href={blogLink.url?.href}
                        target="_blank"
                    >
                        {blogLink.label}
                        <HiOutlineArrowUpRight />
                    </LinkToProjectBlog>
                ) : (
                    <PossibleLink>{blogLink.label}</PossibleLink>
                )}

                <AdditionalInfo>
                    <InfoContainer>
                        <h5>Stack</h5>
                        <Stack stack={stack} />
                    </InfoContainer>

                    <InfoContainer>
                        <h5>Year</h5>
                        <p>{year}</p>
                    </InfoContainer>
                </AdditionalInfo>
            </ProjectContent>

            <ProjectPreviewContainer invert={invert}>
                {component}
            </ProjectPreviewContainer>
        </ProjectContainer>
    );
};

export const ProjectsList = ({ id }: { id: string }) => {
    return (
        <KeyProjectsSection id={id}>
            <ProjectsHeader>
                <h2>Key Projects</h2>
                <h4>Here are the most significant projects I’ve worked on.</h4>
            </ProjectsHeader>

            <ProjectsContainer>
                <Project
                    title={
                        <>
                            Smart chatbot
                            <span style={{ fontWeight: '200' }}>
                                {' '}
                                for better customer conversations
                            </span>
                        </>
                    }
                    description="Built a chatbot that helps businesses respond to customers faster and  more easily across popular platforms like WhatsApp and Facebook, handling most questions automatically and boosting customer satisfaction."
                    blogLink={{
                        label: 'Watch video presentation',
                        url: new URL(
                            'https://www.youtube.com/watch?v=ORMQrfKoEhI&t=2256s'
                        ),
                    }}
                    component={<ChatbotPreview />}
                    stack={ChatbotStack}
                    year="2024 - now"
                />
                <Project
                    title={
                        <>
                            Dental Implant Tracking App
                            <span style={{ fontWeight: '200' }}>
                                {' '}
                                for Health Compliance
                            </span>
                        </>
                    }
                    description={
                        "I've co-founded Health Card Solutions to develop an app that securely tracks dental implants, ensuring compliance with European MDR regulations. Our goal: to promote safer healthcare by providing patients and providers with reliable and accessible implant records."
                    }
                    blogLink={{ label: 'Blog coming soon..' }}
                    component={<DentalForm />}
                    stack={healthCardSolutionsStack}
                    year="2023"
                    invert
                />
            </ProjectsContainer>
        </KeyProjectsSection>
    );
};

import styled from '@emotion/styled';

export const KeyProjectsSection = styled.section`
    display: flex;
    width: 100% !important;
    flex-direction: column;
    gap: 3rem;
`;

export const ProjectsHeader = styled.div`
    @media (max-width: 768px) {
       h2, h4 {
        white-space: normal; /* Allow text to wrap */
        overflow-wrap: break-word; 
       }
    }
`;

export const ProjectsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6rem;

    @media (max-width: 768px) {
        gap: 4rem;
    }
`;

export const ProjectContainer = styled.div<{ invert?: boolean }>`
    display: flex;
    flex-direction: ${({ invert }) => (invert ? 'row-reverse' : 'row')};
    justify-content: space-between;
    align-items: center;
    padding: 3rem;
    gap: 5rem;
    height: 500px;

    border: 1px solid var(--border-color);
    border-radius: 16px;

    @media (max-width: 768px) {
        flex-direction: column;
        height: auto;
        border: none;
        padding: 0;
        gap: 3rem;
    }
`;

export const ProjectContent = styled.div<{ invert?: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;

    h2, p {
        white-space: pre-line;
    },
`;

export const AdditionalInfo = styled.div`
    display: flex;
    flex-direction: row;
    gap: 3rem;
    align-self: flex-start;
`;

export const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;

export const LinkToProjectBlog = styled.a`
    color: var(--primary-color) !important;
    text-transform: uppercase;
    border-bottom: 1px solid var(--primary-color);
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 4px;
`;

export const PossibleLink = styled.p`
    text-transform: uppercase;
    color: var(--disabled-color);
`;

export const StackContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 0.5rem;
    width: fit-content;
`;

export const StackItem = styled.div`
    display: flex;
    align-items: center;
    padding: 0.25rem 0.75rem;
    background: var(--background-color);
    border-radius: 50px;
`;

export const ProjectPreviewContainer = styled.div<{ invert?: boolean }>`
    display: flex;
    border-radius: 16px;
`;

import React from 'react';
import styled from '@emotion/styled';
import Switch from './Switch'; // Import your updated Switch component

/** Interface for SwitchBar props */
interface SwitchBarProps {
    title: string;
    value: boolean;
    onValueChange: (isEnabled: boolean) => void;
    isDisabled?: boolean;
    backgroundColor?: string;
    noBackgroundColor?: boolean;
}

/** Styled Components */
const Container = styled.div<{
    backgroundColor?: string;
    noBackgroundColor?: boolean;
}>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 48px;
    padding: 8px;
    border-radius: 10px;
    background-color: ${(props) =>
        props.noBackgroundColor
            ? 'transparent'
            : props.backgroundColor || '#f0f0f0'};
    box-sizing: border-box;
`;

const Label = styled.span<{ isDisabled?: boolean }>`
    margin-left: 4px;
    font-weight: bold;
    color: ${(props) => (props.isDisabled ? '#A9A9A9' : '#000')};
`;

/** SwitchBar Component */
const SwitchBar: React.FC<SwitchBarProps> = ({
    value,
    onValueChange,
    title,
    isDisabled = false,
    backgroundColor,
    noBackgroundColor,
}) => {
    return (
        <Container
            backgroundColor={backgroundColor}
            noBackgroundColor={noBackgroundColor}
        >
            <Label isDisabled={isDisabled}>{title}</Label>
            <Switch
                checked={value}
                onChange={(checked) => {
                    if (!isDisabled) {
                        onValueChange(checked);
                    }
                }}
                isDisabled={isDisabled}
            />
        </Container>
    );
};

export default SwitchBar;

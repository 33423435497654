import React from 'react';

const GitHubIcon = () => {
    return (
        <svg
            width="32"
            height="33"
            viewBox="0 0 32 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.9875 2.88437C8.25937 2.88125 2 9.1375 2 16.8594C2 22.9656 5.91562 28.1562 11.3687 30.0625C12.1031 30.2469 11.9906 29.725 11.9906 29.3687V26.9469C7.75 27.4437 7.57812 24.6375 7.29375 24.1687C6.71875 23.1875 5.35937 22.9375 5.76562 22.4687C6.73125 21.9719 7.71562 22.5937 8.85625 24.2781C9.68125 25.5 11.2906 25.2937 12.1062 25.0906C12.2844 24.3562 12.6656 23.7 13.1906 23.1906C8.79687 22.4031 6.96562 19.7219 6.96562 16.5344C6.96562 14.9875 7.475 13.5656 8.475 12.4187C7.8375 10.5281 8.53437 8.90937 8.62812 8.66875C10.4437 8.50625 12.3312 9.96875 12.4781 10.0844C13.5094 9.80625 14.6875 9.65937 16.0062 9.65937C17.3312 9.65937 18.5125 9.8125 19.5531 10.0937C19.9062 9.825 21.6562 8.56875 23.3437 8.72187C23.4344 8.9625 24.1156 10.5437 23.5156 12.4094C24.5281 13.5594 25.0437 14.9937 25.0437 16.5437C25.0437 19.7375 23.2 22.4219 18.7937 23.1969C19.1712 23.568 19.4708 24.0107 19.6752 24.4989C19.8797 24.9872 19.9848 25.5113 19.9844 26.0406V29.5562C20.0094 29.8375 19.9844 30.1156 20.4531 30.1156C25.9875 28.25 29.9719 23.0219 29.9719 16.8625C29.9719 9.1375 23.7094 2.88437 15.9875 2.88437Z"
                fill="black"
            />
        </svg>
    );
};

export default GitHubIcon;

export const SparklesLogo = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M24.2931 24.518L21.0291 16.0325C20.9802 15.9052 20.8937 15.7957 20.7813 15.7184C20.6688 15.6412 20.5356 15.5999 20.3991 15.5999C20.2627 15.5999 20.1295 15.6412 20.017 15.7184C19.9045 15.7957 19.8181 15.9052 19.7691 16.0325L16.5051 24.518C16.4712 24.6062 16.4192 24.6863 16.3524 24.753C16.2856 24.8198 16.2056 24.8719 16.1174 24.9058L7.63189 28.1698C7.50455 28.2188 7.39504 28.3052 7.3178 28.4176C7.24056 28.5301 7.19922 28.6633 7.19922 28.7998C7.19922 28.9362 7.24056 29.0694 7.3178 29.1819C7.39504 29.2944 7.50455 29.3808 7.63189 29.4298L16.1174 32.6938C16.2056 32.7277 16.2856 32.7797 16.3524 32.8465C16.4192 32.9133 16.4712 32.9934 16.5051 33.0815L19.7691 41.567C19.8181 41.6944 19.9045 41.8039 20.017 41.8811C20.1295 41.9583 20.2627 41.9997 20.3991 41.9997C20.5356 41.9997 20.6688 41.9583 20.7813 41.8811C20.8937 41.8039 20.9802 41.6944 21.0291 41.567L24.2931 33.0815C24.327 32.9934 24.3791 32.9133 24.4459 32.8465C24.5127 32.7797 24.5927 32.7277 24.6809 32.6938L33.1664 29.4298C33.2937 29.3808 33.4032 29.2944 33.4805 29.1819C33.5577 29.0694 33.5991 28.9362 33.5991 28.7998C33.5991 28.6633 33.5577 28.5301 33.4805 28.4176C33.4032 28.3052 33.2937 28.2188 33.1664 28.1698L24.6809 24.9058C24.5927 24.8719 24.5127 24.8198 24.4459 24.753C24.3791 24.6863 24.327 24.6062 24.2931 24.518Z"
            stroke="#1480EE"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M12.9 9.90024L11.4 6.00024L9.9 9.90024L6 11.4002L9.9 12.9002L11.4 16.8002L12.9 12.9002L16.8 11.4002L12.9 9.90024Z"
            stroke="#1480EE"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M36.8018 13.6004L34.8016 8.40063L32.8013 13.6004L27.6016 15.6006L32.8013 17.6009L34.8016 22.8006L36.8018 17.6009L42.0016 15.6006L36.8018 13.6004Z"
            stroke="#1480EE"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);

import React, { useState, useEffect, useRef } from 'react';
import {
  ChatbotFrame,
  ChatbotFrameContent,
  ChatbotFrameFooter,
  ChatbotFrameHeader,
  ChatbotTextArea,
  JimHeader,
  JimText,
  JimUser,
  MessageBubble,
  MessageBubbleWrapper,
  ResetButton,
  SendMessageButton,
  TypingIndicator,
} from './DhatbotPreview.styled';
import { JimIcon } from './JimIcon';
import { SendMessageIcon } from './SendMessageIcon';
import { TfiReload } from "react-icons/tfi";

export const ChatbotPreview = () => {
  const initialMessageList = [
    { content: 'Bonjour 👋 ! Comment puis-je vous aider ?', role: 'bot' },
    {
      content: 'Bonjour, quel est le coût pour réparer un vélo ?',
      role: 'user',
    },
    {
      content:
        'Cela dépend du problème rencontré :\n' +
        '- Le type de vélo (VTT, électrique, etc.)\n' +
        '- Les pièces à changer\n' +
        '- Le temps nécessaire\n\n' +
        'Contactez-nous au +33687137436 pour un devis 😊'
      ,
      role: 'bot',
    },
  ];

  // État pour tous les messages affichés dans le chatbot
  const [messages, setMessages] = useState<
    { content: string; role: string }[]
  >([]);
  // État pour gérer l'animation des messages initiaux
  const [isAnimating, setIsAnimating] = useState(true);
  // État pour l'entrée de l'utilisateur
  const [messageInput, setMessageInput] = useState('');
  // État pour afficher l'indicateur de saisie du bot
  const [isBotTyping, setIsBotTyping] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  // Références pour le défilement
  const chatbotFrameContentRef = useRef<HTMLDivElement>(null);
  const chatbotInputRef = useRef<HTMLTextAreaElement>(null);

  // Référentiel pour éviter les appels multiples
  const hasInitialized = useRef(false);

  useEffect(() => {
    console.log('currentIndex', currentIndex, isAnimating);
  }, [currentIndex, isAnimating]);

  const displayInitialMessages = async () => {
    if (currentIndex >= initialMessageList.length) {
      setIsAnimating(false);
      return;
    }

    for (let i = 0; i < initialMessageList.length; i++) {
      const message = initialMessageList[i];

      // Délai avant d'afficher le prochain message
      await new Promise((resolve) => setTimeout(resolve, 1000));

      if (message.role === 'bot') {
        setIsBotTyping(true);
        // Délai pour simuler la saisie du bot
        await new Promise((resolve) => setTimeout(resolve, 1500));
        setMessages((prev) => [...prev, message]);
        setIsBotTyping(false);
      } else {
        setMessages((prev) => [...prev, message]);
      }
      setCurrentIndex(i + 1);
    }
    setIsAnimating(false);
  };

  // Fonction pour animer l'affichage des messages initiaux
  useEffect(() => {
    if (isAnimating && !hasInitialized.current) {
      hasInitialized.current = true;
      displayInitialMessages();
    }
  }, [isAnimating]);

  // Fonction pour gérer l'envoi des messages par l'utilisateur
  const handleSendMessage = async () => {
    if (messageInput.trim() === '') return;

    const userMessage = { content: messageInput, role: 'user' };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setMessageInput('');
    await new Promise((resolve) => setTimeout(resolve, 1500));
    setIsBotTyping(true);

    // Simuler une réponse du bot après un délai
    setTimeout(() => {
      const botResponse = {
        content: 'Hmmmm..',
        role: 'bot',
      };
      setMessages((prevMessages) => [...prevMessages, botResponse]);
      setIsBotTyping(false);
    }, 1500);
  };

  // Fonction pour réinitialiser la conversation
  const handleResetConversation = () => {
    setMessages([]);
    setMessageInput('');
    setIsBotTyping(false);

    // Relancer l'animation des messages initiaux
    setIsAnimating(true);
    hasInitialized.current = false;
    setCurrentIndex(0);
  };

  // Effet pour faire défiler automatiquement vers le bas lorsque de nouveaux messages sont ajoutés
  useEffect(() => {
    if (chatbotFrameContentRef.current) {
      chatbotFrameContentRef.current.scrollTo({
        top: chatbotFrameContentRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [messages, isBotTyping]);

  // Gestion de l'envoi du message avec la touche Entrée
  const onEnterKeyDown = (
    event: React.KeyboardEvent<HTMLTextAreaElement>
  ) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <ChatbotFrame>
      {/* HEADER */}
      <ChatbotFrameHeader>
        <JimHeader>
          <JimIcon />
          <JimText>Jim</JimText>
        </JimHeader>
        <ResetButton onClick={handleResetConversation}>
          <TfiReload /> Reload
        </ResetButton>
      </ChatbotFrameHeader>

      {/* CONTENU */}
      <ChatbotFrameContent ref={chatbotFrameContentRef}>

        {messages.map((message, index) => (
          <MessageBubbleWrapper
            key={index}
            isConsumer={message.role === 'user'}
          >
            {message.role === 'bot' && (
              <JimUser>
                <JimIcon />
              </JimUser>
            )}
            <MessageBubble isConsumer={message.role === 'user'}>
              {message.content}
            </MessageBubble>
          </MessageBubbleWrapper>
        ))}

        {isBotTyping && (
          <MessageBubbleWrapper isConsumer={false}>
            <MessageBubbleWrapper>
              <JimUser>
                <JimIcon />
              </JimUser>
              <TypingIndicator />
            </MessageBubbleWrapper>
          </MessageBubbleWrapper>
        )}

      </ChatbotFrameContent>

      {/* FOOTER */}
      <ChatbotFrameFooter>
        <ChatbotTextArea
          placeholder="Type your message..."
          value={messageInput}
          onChange={(e) => setMessageInput(e.target.value)}
          onKeyDown={onEnterKeyDown}
          ref={chatbotInputRef}
        />
        <SendMessageButton onClick={handleSendMessage}>
          <SendMessageIcon />
        </SendMessageButton>
      </ChatbotFrameFooter>
    </ChatbotFrame>
  );
};

import React from 'react';
import { COLORS } from '../../Constants';

export const DatamatrixScan: React.FC<React.SVGProps<SVGSVGElement>> = (
    props
) => (
    <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M7.5 7.5H12.5V5H7.5C6.125 5 5 6.125 5 7.5V12.5H7.5V7.5ZM32.5 5H27.5V7.5H32.5V12.5H35V7.5C35 6.125 33.875 5 32.5 5ZM7.5 27.5H5V32.5C5 33.875 6.125 35 7.5 35H12.5V32.5H7.5V27.5ZM32.5 32.5H27.5V35H32.5C33.875 35 35 33.875 35 32.5V27.5H32.5V32.5ZM15 12.5V10H17.5V12.5H15ZM17.5 22.5V20H20V22.5H17.5ZM25 10H27.5V12.5H25V10ZM27.5 12.5H30V15H27.5V12.5ZM27.5 22.5H30V25H27.5V22.5ZM30 20H25V27.5H30V30H10V10H12.5V12.5H15V17.5H12.5V20H15V22.5H17.5V25H20V27.5H22.5V25H20V22.5H22.5V17.5H17.5V12.5H20V10H22.5V15H25V17.5H30V20ZM15 27.5V25H12.5V27.5H15Z" />
    </svg>
);

export default DatamatrixScan;

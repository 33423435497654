import styled from '@emotion/styled';
import * as React from 'react';

interface ToothProps extends React.SVGProps<SVGSVGElement> {
    color?: string;
    style?: React.CSSProperties;
}

export const ToothIcon: React.FC<ToothProps> = ({
    color = '#121212', // Default color
    style,
    ...props
}) => (
    <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={style}
        {...props}
    >
        <path
            d="M19.1064 2.13818C18.0367 0.696907 16.9128 0.025645 15.5696 0.025645C14.7 0.025645 13.8561 0.304561 13.0415 0.573724L13.0145 0.582601C12.1455 0.867983 11.3246 1.13769 10.4389 1.13769C9.55125 1.13769 8.6813 0.845736 7.83458 0.561778C7.00396 0.285492 6.14519 0 5.28553 0C3.93567 0 2.83819 0.684852 1.83223 2.15451C0.249805 4.45915 1.14826 6.9271 1.873 8.91743C2.19575 9.80295 2.50053 10.6393 2.50053 11.2707C2.50053 14.5131 3.18429 20.0001 5.74714 20.0001C7.50108 20.0001 8.04072 17.9622 8.51811 16.1592C9.02498 14.2611 9.46391 12.9176 10.5011 12.9176C11.3959 12.9176 11.7383 13.7875 12.213 15.8727C12.6237 17.6946 13.1349 19.9618 15.2905 19.9618C18.2983 19.9618 18.532 13.3063 18.532 11.2666C18.532 10.6679 18.8267 9.83857 19.1389 8.9605L19.1512 8.92543C19.8693 6.89411 20.7629 4.36622 19.1064 2.13818ZM17.6014 8.37746L17.59 8.4099C17.2291 9.42529 16.8882 10.3843 16.8882 11.2665C16.8882 13.2024 16.6846 15.079 16.3294 16.4151C15.9145 17.9759 15.4387 18.3177 15.2905 18.3177C14.6303 18.3177 14.3134 17.7144 13.8149 15.5033C13.4085 13.7182 12.8521 11.2735 10.5011 11.2735C8.12138 11.2735 7.4327 13.852 6.92769 15.7434C6.65217 16.7837 6.23593 18.356 5.74725 18.356C5.67141 18.356 5.19391 18.0263 4.75674 16.4597C4.36768 15.0656 4.14466 13.1743 4.14466 11.2705C4.14466 10.3488 3.79155 9.38003 3.41531 8.34765C2.76093 6.55042 2.0843 4.69193 3.18835 3.08397C4.05666 1.81498 4.73581 1.64391 5.28553 1.64391C5.87909 1.64391 6.57699 1.87592 7.31696 2.12196C8.23843 2.43112 9.28275 2.7816 10.4387 2.7816C11.5874 2.7816 12.6181 2.44318 13.5274 2.14453L13.5558 2.13522C14.2802 1.89575 14.9646 1.66966 15.5696 1.66966C16.1341 1.66966 16.839 1.84172 17.7868 3.1186C18.9212 4.64415 18.2503 6.5421 17.6014 8.37746Z"
            fill={color}
        />
    </svg>
);

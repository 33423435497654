import React from 'react';
import {
    Brand,
    NavigationContainer,
    NavItem,
    NavItems,
} from './NavigationBar.styled';

const NavigationBar = () => {
    return (
        <NavigationContainer>
            <Brand>Eliyahou</Brand>
            <NavItems>
                <NavItem href="#projects">Projects</NavItem>
                <NavItem href="#services">Services</NavItem>
                <NavItem href="#about">About Me</NavItem>
            </NavItems>
        </NavigationContainer>
    );
};

export default NavigationBar;

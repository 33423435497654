import React from 'react';

import DentalInterventionForm from './DentalInterventionForm';
import { IPhonePreview } from './icons/iPhonePreview';
import { DentalInterventionFormProvider } from './DentalFormContext';

export const DentalForm = () => {
    return (
        <DentalInterventionFormProvider>
            <IPhonePreview>
                <DentalInterventionForm />
            </IPhonePreview>
        </DentalInterventionFormProvider>
    );
};

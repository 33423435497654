import './App.css';
import NavigationBar from './sections/NavigationBar';
import Home from './sections/Home';
import { ProjectsList } from './sections/Project';
import { AppContainer } from './App.styled';
import ServicesList from './sections/Services';
import { About } from './sections/About';

export const App = () => {
    return (
        <AppContainer>
            <NavigationBar />
            <Home />
            <ProjectsList id="projects" />
            <ServicesList id="services" />
            <About id="about" />
        </AppContainer>
    );
};

export default App;

import React from 'react';
import { Controller, Control } from 'react-hook-form';
import SwitchBar from './SwitchBar'; // Import your updated SwitchBar component

/** Interface for SwitchBarField props */
interface SwitchBarFieldProps {
    name: string;
    control: Control<any>;
    title: string;
    isDisabled?: boolean;
    isTogglable?: boolean;
    backgroundColor?: string;
    noBackgroundColor?: boolean;
}

/** SwitchBarField Component */
export const SwitchBarField: React.FC<SwitchBarFieldProps> = ({
    control,
    name,
    title,
    isTogglable = true,
    isDisabled,
    ...props
}) => {
    return (
        <Controller
            control={control}
            name={name}
            defaultValue={false}
            render={({ field: { onChange, value } }) => (
                <SwitchBar
                    title={title}
                    value={value}
                    onValueChange={
                        isTogglable
                            ? (state: boolean) => {
                                  onChange(state);
                              }
                            : () => undefined
                    }
                    isDisabled={isDisabled}
                    {...props}
                />
            )}
        />
    );
};

export default SwitchBarField;

export const PythonLogo = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M14.3302 3.67614C13.5833 3.5532 12.49 3.49655 11.748 3.50016C11.0524 3.50097 10.3581 3.55983 9.67236 3.67614C7.83462 3.99757 7.49994 4.66854 7.49994 5.90763V7.35725H11.9999V8.0001H6.03826C4.36847 8.0001 3.01326 9.99574 3.00001 12.4647V12.5C2.99943 12.9317 3.04155 13.3624 3.12576 13.7857C3.49902 15.6452 4.66177 17 6.03826 17H6.85709V14.8304C6.85709 13.5728 7.6944 12.4217 9.06688 12.1746L9.46344 12.1252H14.3302C14.5346 12.126 14.7382 12.0989 14.9353 12.0448C15.3859 11.9245 15.784 11.6581 16.0671 11.2874C16.3502 10.9167 16.5024 10.4626 16.4998 9.99614V5.90763C16.4998 4.74568 15.5082 3.8694 14.3302 3.67614ZM9.5462 6.45324C9.3841 6.4538 9.22548 6.4062 9.09046 6.31649C8.95544 6.22677 8.8501 6.09898 8.78781 5.94932C8.72551 5.79966 8.70907 5.63487 8.74056 5.47584C8.77205 5.31682 8.85005 5.17074 8.96468 5.05611C9.07931 4.94148 9.2254 4.86348 9.38442 4.83199C9.54344 4.8005 9.70823 4.81694 9.85789 4.87924C10.0076 4.94153 10.1353 5.04686 10.2251 5.18188C10.3148 5.3169 10.3624 5.47552 10.3618 5.63763C10.3619 5.74475 10.3408 5.85084 10.2998 5.94982C10.2589 6.04879 10.1988 6.13873 10.123 6.21447C10.0473 6.29022 9.95737 6.3503 9.85839 6.39127C9.75941 6.43224 9.65333 6.4533 9.5462 6.45324V6.45324Z"
            fill="#3979AB"
        />
        <path
            d="M20.8102 10.9331C20.3803 9.22113 19.2669 8.00012 17.9615 8.00012H17.25V9.90657C17.25 11.4964 16.2054 12.6475 14.9301 12.8448C14.8013 12.8648 14.6711 12.8748 14.5408 12.8749H9.67282C9.46634 12.8747 9.26069 12.901 9.06091 12.9533C8.16454 13.1887 7.5 13.9344 7.5 14.9015V18.9904C7.5 20.1556 8.67079 20.8386 9.81947 21.1725C11.194 21.5722 12.6914 21.6446 14.3363 21.1725C15.4287 20.8603 16.4999 20.2291 16.4999 18.9904V17.6428H11.9999V17H17.9615C19.1444 17 20.1693 15.9984 20.6715 14.5367C20.8921 13.8804 21.003 13.1924 20.9998 12.5001C21.0009 11.972 20.9372 11.4457 20.8102 10.9331ZM14.4311 18.4464C14.5932 18.4458 14.7518 18.4934 14.8869 18.5832C15.0219 18.6729 15.1272 18.8007 15.1895 18.9503C15.2518 19.1 15.2682 19.2648 15.2367 19.4238C15.2053 19.5828 15.1273 19.7289 15.0126 19.8435C14.898 19.9582 14.7519 20.0362 14.5929 20.0677C14.4339 20.0991 14.2691 20.0827 14.1194 20.0204C13.9698 19.9581 13.842 19.8528 13.7522 19.7178C13.6625 19.5827 13.6149 19.4241 13.6155 19.262C13.6154 19.1549 13.6365 19.0488 13.6775 18.9498C13.7184 18.8508 13.7785 18.7609 13.8543 18.6852C13.93 18.6094 14.0199 18.5493 14.1189 18.5084C14.2179 18.4674 14.324 18.4463 14.4311 18.4464Z"
            fill="#FFDA46"
        />
    </svg>
);

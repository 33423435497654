export const BiCodeAltLogo = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M15.6754 32.6072L17.9253 29.7957L10.6824 24.0018L17.9253 18.2078L15.6754 15.3964L6.67581 22.596C6.46494 22.7647 6.29471 22.9786 6.17773 23.2219C6.06074 23.4652 6 23.7318 6 24.0018C6 24.2718 6.06074 24.5383 6.17773 24.7816C6.29471 25.025 6.46494 25.2389 6.67581 25.4075L15.6754 32.6072ZM32.3246 15.3964L30.0747 18.2078L37.3176 24.0018L30.0747 29.7957L32.3246 32.6072L41.3242 25.4075C41.5351 25.2389 41.7053 25.025 41.8223 24.7816C41.9393 24.5383 42 24.2718 42 24.0018C42 23.7318 41.9393 23.4652 41.8223 23.2219C41.7053 22.9786 41.5351 22.7647 41.3242 22.596L32.3246 15.3964ZM29.3565 8.19132L22.1569 40.5898L18.6417 39.8086L25.8413 7.41016L29.3565 8.19132Z"
            fill="#1480EE"
        />
    </svg>
);

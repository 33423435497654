// ImplantField.tsx
import React from 'react';
import { Controller, Control, UseFormSetValue } from 'react-hook-form';
import {
    ImplantContainer,
    Title,
    UDIInputContainer,
    UDIInputWrapper,
    UDIInput,
    UDIButton,
    CommentContainer,
    CommentInput,
    ErrorContainer,
    ErrorMessage,
} from './ImplantField.styled';
import { DatamatrixScan } from './icons/DatamatrixScan';
import { DentalFormData, ImplantFieldName } from './DentalFormContext';

type Props = {
    name: ImplantFieldName;
    control: Control<DentalFormData>;
    setValue: UseFormSetValue<DentalFormData>;
    defaultTitle?: string;
};

const ImplantField: React.FC<Props> = ({
    name,
    control,
    setValue,
    defaultTitle = 'Implant',
}) => {
    const handleButtonClick = () => {
        // Replace the following line with actual scan logic
        const scannedValue = prompt('Enter scanned UDI-DI value:');
        if (scannedValue !== null) {
            setValue(`${name}.udiDi` as const, scannedValue, {
                shouldValidate: true,
            });
        }
    };

    return (
        <ImplantContainer>
            <Title>{defaultTitle}</Title>
            <UDIInputContainer>
                <label htmlFor={`${name}.udiDi`}>UDI-DI</label>
                <UDIInputWrapper>
                    <Controller
                        name={`${name}.udiDi`}
                        control={control}
                        rules={{ required: 'UDI-DI est requis' }}
                        render={({ field, fieldState }) => (
                            <ErrorContainer>
                                <UDIInput
                                    id={`${name}.udiDi`}
                                    type="text"
                                    placeholder="(01)01865494261654"
                                    error={!!fieldState.error}
                                    {...field}
                                />
                                {fieldState.error && (
                                    <ErrorMessage>
                                        {fieldState.error.message}
                                    </ErrorMessage>
                                )}
                            </ErrorContainer>
                        )}
                    />
                    <UDIButton type="button" onClick={handleButtonClick}>
                        <DatamatrixScan />
                    </UDIButton>
                </UDIInputWrapper>
            </UDIInputContainer>
            <CommentContainer>
                <label htmlFor={`${name}.comment`}>Commentaire</label>
                <Controller
                    name={`${name}.comment`}
                    control={control}
                    render={({ field }) => (
                        <CommentInput
                            id={`${name}.comment`}
                            placeholder="Commentaire sur l'implant"
                            rows={3}
                            {...field}
                        />
                    )}
                />
            </CommentContainer>
        </ImplantContainer>
    );
};

export default ImplantField;

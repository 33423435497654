import styled from '@emotion/styled';
import { COLORS } from '../Constants';

export const ImplantContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;

    border-radius: 10px;
    padding: 16px;
    width: 100%;
    border: 2px solid ${COLORS.grey};
    box-sizing: border-box;
    overflow: hidden;
`;

export const Title = styled.h4`
    font-size: 1rem;
    font-weight: bold;
    color: ${COLORS.primary};
`;

export const UDIInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;

    label {
        font-weight: bold;
    }
`;

export const UDIInputWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 8px;
`;

export const ErrorContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px
`;

export const ErrorMessage = styled.span`
    color: ${COLORS.danger};
    font-size: 0.75rem;
    margin-top: 4px;
`;

export const UDIInput = styled.input<{ error?: boolean }>`
    flex: 1; /* Input takes the available space */
    padding: 12px;
    border-radius: 4px;
    ${(props) =>
        props.error
            ? `border: 1px solid ${COLORS.danger};`
            : `border: 1px solid #ccc;`}
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    background-color: transparent;
`;

export const UDIButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border: none;
    border-radius: 8px;
    background-color: ${COLORS.grey};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Light shadow for depth */
    cursor: pointer;
    transition: transform 0.2s;

    &:hover {
        transform: scale(1.05); /* Slight zoom on hover */
    }

    svg {
        fill: ${COLORS.primary}; /* White color for the SVG */
        width: 28px;
        height: 28px;
    }
`;

export const CommentContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;

    label {
        font-weight: bold;
    }
`;

export const CommentInput = styled.textarea`
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    resize: vertical;
    box-sizing: border-box;
    overflow: hidden;
    background-color: transparent;
    font-family: var(--health-card-font);
    font-size: 14px;
`;

import styled from '@emotion/styled';

export const NavigationContainer = styled.nav`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0;
`;

export const Brand = styled.div`
    font-size: var(--font-size-h4);
    font-weight: bold;
    color: var(--html-color);
`;

export const NavItems = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    gap: 4rem;
    border-radius: 50px;
    padding: 0.75rem 2rem;

    @media (max-width: 768px) {
        display: none;
  }
`;

export const NavItem = styled.a`
    text-decoration: none;
    text-wrap: nowrap;
    font-weight: 300;
    color: var(--html-color);
    
    &:hover {
        color: var(--primary-color);
    }
`;
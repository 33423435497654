// Services.styled.ts
import styled from '@emotion/styled';

export const ServicesContainer = styled.section`
    display: flex;
    flex-direction: column;
    margin-top: 4rem;
    gap: 3rem;
`;

export const ServicesItems = styled.ul`
    width: 100%;
    display: flex;
    gap: 1rem;
    padding: 0;
    flex-wrap: wrap;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const ServicesItem = styled.li<{ popular: boolean }>`
    position: relative;
    background-color: var(--card-background);
    border: 1px solid ${(props) => props.popular ? 'var(--primary-color)' : 'var(--border-color)'};
    border-radius: 16px;
    padding: 1rem;
    box-sizing: border-box;

    // Base width to one-third of the container width, minus the gap and paddings.
    flex: 1 0 calc((100% - 2rem) / 3);
    max-width: calc((100% - 2rem) / 3);

    display: flex;
    flex-direction: column;
    justify-content: space-between; 
    gap: 1rem;

    @media (max-width: 768px) {
        flex: 1 0 100%;
        max-width: 100%;
    }
`;

export const ServieItemTick = styled.p`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%); 
    display: flex;
    width: fit-content;
    font-weight: bold;
    color: var(--primary-color);
    border-radius: 50px;
    padding: 2px 8px;
    background-color: var(--primary-background-color);
`;


// to be used.
export const ServiceButton = styled.button`
    padding: 0.75rem 1.5rem;
    background-color: var(--primary-color);
    color: #ffffff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    align-self: flex-start; /* Aligns the button to the start */
    transition: background-color 0.2s;

    &:hover,
    &:focus {
        background-color: #005bb5;
    }

    &:active {
        transform: scale(0.98);
    }
`;

/** @jsxImportSource @emotion/react */
import React from 'react';
import styled from '@emotion/styled';

/** Styled Components */
const SwitchContainer = styled.label`
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
`;

const SwitchInput = styled.input`
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + span {
        background-color: #0085f2;
    }

    &:checked + span::before {
        transform: translateX(26px);
    }

    &:disabled + span {
        background-color: #ccc;
        cursor: not-allowed;
    }

    &:disabled + span::before {
        background-color: #999;
    }
`;

const SwitchSlider = styled.span`
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;

    &::before {
        position: absolute;
        content: '';
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: #fff;
        transition: 0.4s;
        border-radius: 50%;
    }
`;

/** Type Definitions */
type SwitchProps = {
    checked: boolean;
    onChange: (checked: boolean) => void;
    isDisabled?: boolean;
};

/** Switch Component */
const Switch: React.FC<SwitchProps> = ({ checked, onChange, isDisabled }) => {
    return (
        <SwitchContainer>
            <SwitchInput
                type="checkbox"
                checked={checked}
                onChange={(e) => onChange(e.target.checked)}
                disabled={isDisabled}
            />
            <SwitchSlider />
        </SwitchContainer>
    );
};

export default Switch;

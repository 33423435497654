import styled from '@emotion/styled';

export const AboutContainer = styled.section`
    display: flex;
    width: 100% !important;
    flex-direction: column;
    gap: 3rem;

    @media (max-width: 768px) {
        h2 {
            line-height: 1;
            margin-bottom: 0.5rem;
        }
    }
`;


export const CTAButton = styled.a`
    width: fit-content;
    padding: 0.75rem 1rem;
    background-color: var(--primary-color);
    color: white !important;
    
    border-radius: 50px;
    text-decoration: none;
    font-size: 1.25rem;
    font-weight: bold;
    transition: background-color 0.3s ease;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;

    &:hover {
        background-color: var(--primary-hover-color);
    }
    
    @media (max-width: 768px) {
        align-self: center;
    }
`;

// DentalInterventionFormContext.tsx
import React, { createContext, useState, ReactNode } from 'react';

// Type Definitions
type Implant = {
    udiDi: string;
    comment: string;
};

export type DentalFormData = {
    has_transplant: boolean;
    transplant_method: string;
    transplant_type: string;
    transplant_implant: Implant;
    has_membrane: boolean;
    membrane_implant: Implant;
    bone_type: string;
    torque: number | null;
    has_suture: boolean;
    suture_is_absorbable: boolean;
    suture_size: string;
    has_loading_type: boolean;
    implant_loading_type: string;
    has_incident: boolean;
    incident: string;
};

export type ImplantFieldName = {
    [K in keyof DentalFormData]: DentalFormData[K] extends Implant ? K : never;
}[keyof DentalFormData];

// Default Values
const defaultImplant: Implant = {
    udiDi: '',
    comment: '',
};

export const defaultValues: DentalFormData = {
    has_transplant: false,
    transplant_method: '',
    transplant_type: '',
    transplant_implant: { ...defaultImplant },
    has_membrane: false,
    membrane_implant: { ...defaultImplant },
    bone_type: '',
    torque: null,
    has_suture: false,
    suture_is_absorbable: false,
    suture_size: '',
    has_loading_type: false,
    implant_loading_type: '',
    has_incident: false,
    incident: '',
};

// Context Interface
interface FormContextProps {
    formData: DentalFormData;
    setFormData: React.Dispatch<React.SetStateAction<DentalFormData>>;
}

// Default Context Value
const defaultFormContext: FormContextProps = {
    formData: defaultValues,
    setFormData: () => {
        console.warn('setFormData is not implemented');
    },
};

// Create Context
export const DentalInterventionFormContext =
    createContext<FormContextProps>(defaultFormContext);

// Provider Component
interface ProviderProps {
    children: ReactNode;
}

export const DentalInterventionFormProvider: React.FC<ProviderProps> = ({
    children,
}) => {
    const [formData, setFormData] = useState<DentalFormData>(defaultValues);

    return (
        <DentalInterventionFormContext.Provider
            value={{ formData, setFormData }}
        >
            {children}
        </DentalInterventionFormContext.Provider>
    );
};
